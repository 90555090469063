import React from 'react';
import {
  createQuote,
  loadFiles,
  loadPublicFile,
  loadQuoteRequest,
} from '@nimles/react-redux';
import {
  ProductGroupModel,
  ProductPropertyModel,
  QuoteModel,
  QuoteRequestModel,
  FileModel,
  CategoryModel,
  OrganizationModel,
  UserModel,
} from '@nimles/models';
import {
  Card,
  CardAction,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Column,
  Image,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import moment from 'moment';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { Property, PropertyTitle, PropertyValue } from '../../Property';

export const QuoteRequest = ({ quoteRequestId, ...props }) => {
  const dispatch = useDispatch();
  const quoteRequests = useSelector<RootState, QuoteRequestModel[]>(
    ({ quoteRequests }) => quoteRequests.values
  );
  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const files = useSelector<RootState, FileModel[]>(
    ({ files }) => files.values
  );
  const publicFiles = useSelector<RootState, FileModel[]>(
    ({ publicFiles }) => publicFiles.values
  );
  const publicProductProperties = useSelector<
    RootState,
    ProductPropertyModel[]
  >(({ publicProductProperties }) => publicProductProperties.values);
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );
  const organization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );

  useEffect(() => {
    dispatch(loadFiles());
  }, [accessToken]);

  useEffect(() => {
    if (quoteRequestId) {
      dispatch(loadQuoteRequest(quoteRequestId));
    }
  }, [quoteRequestId, accessToken]);

  const quoteRequest = useMemo(
    () => quoteRequests.find(({ id }) => id === quoteRequestId),
    [quoteRequests, quoteRequestId]
  );
  const quote = useMemo(
    () => quotes.find(({ head }) => head?.quoteRequestId === quoteRequestId),
    [quotes, quoteRequestId]
  );

  useEffect(() => {
    if (quoteRequest) {
      quoteRequest.lines.forEach((line) => {
        if (!line.fileIds) {
          return;
        }
        line.fileIds
          .filter((fileId) => !publicFiles.some(({ id }) => id === fileId))
          .forEach((fileId) => dispatch(loadPublicFile(fileId)));
      });
    }
  }, [quoteRequest]);

  if (!quoteRequest) {
    return null;
  }

  const {
    head: { quoteRequestNumber, quoteRequestDate, categoryId },
    lines,
  } = quoteRequest;

  const category = publicCategories.find(({ id }) => id === categoryId);

  const handleCreateQuote = async () => {
    const quote: QuoteModel = {
      head: {
        quoteRequestId: quoteRequestId,
        seller: {
          organizationId: organization.id,
          userId: user.id,
        },
      },

      lines: quoteRequest.lines?.map(({ productGroupId }) => ({
        productGroupId,
      })),
    };
    const quoteResponse: any = await dispatch(createQuote(quote));
    navigate(`/company/quotes/${quoteResponse.id}`);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{category?.name}</CardTitle>
        <List>
          <ListItem>Number: {quoteRequestNumber}</ListItem>
          <ListItem>Date: {moment(quoteRequestDate).format('MMM D')}</ListItem>
        </List>
      </CardHeader>
      <CardBody>
        {lines.map(
          ({
            id,
            categoryId,
            productGroupId,
            productProperties,
            description,
            fileIds,
          }) => {
            const category = publicCategories.find(
              ({ id }) => id === categoryId
            );
            const productGroup = publicProductGroups.find(
              ({ id }) => id === productGroupId
            );

            return (
              <div key={id}>
                <h4>{productGroup?.name}</h4>
                {productProperties.map(({ productPropertyId, values }) => {
                  const productProperty = publicProductProperties.find(
                    ({ id }) => id === productPropertyId
                  );

                  if (!productProperty) {
                    return (
                      <div key={productPropertyId}>{productPropertyId}</div>
                    );
                  }

                  const {
                    id,
                    name,
                    title,
                    propertyType,
                    options,
                  } = productProperty;

                  return (
                    <Property key={id}>
                      <PropertyTitle>{title}</PropertyTitle>
                      {values?.map((value, index) => (
                        <PropertyValue key={index}>
                          {propertyType.indexOf('Option') !== -1
                            ? options.find(
                                ({ id }) => value === id.replace(/-/g, '')
                              )?.name ?? value
                            : value}
                        </PropertyValue>
                      ))}
                    </Property>
                  );
                })}
                <PropertyTitle>Description</PropertyTitle>
                <PropertyValue
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <PropertyTitle>Files</PropertyTitle>
                {fileIds
                  ?.map((fileId) => files.find(({ id }) => id === fileId))
                  .filter((file) => file)
                  .map(({ id, name, mimeType, uri }) => (
                    <ListItem key={id}>
                      <Row wrap="wrap" align="center">
                        <Column xs={100} sm={20} align="center">
                          {mimeType?.toLowerCase()?.indexOf('image') === 0 ? (
                            <Image src={uri} alt="" />
                          ) : (
                            mimeType
                              ?.split('/')
                              .map((s, index) => <div key={index}>{s}</div>)
                          )}
                        </Column>
                        <Column flex>
                          <div>{name}</div>
                        </Column>
                      </Row>
                    </ListItem>
                  ))}
              </div>
            );
          }
        )}
      </CardBody>
      <CardActions>
        {quote ? (
          <CardAction
            primary
            raised
            onClick={() => navigate(`/company/quotes/${quote.id}`)}
          >
            View Quote
          </CardAction>
        ) : (
          <CardAction primary raised onClick={handleCreateQuote}>
            Create Quote
          </CardAction>
        )}
      </CardActions>
    </Card>
  );
};
