import React, { FC, InputHTMLAttributes, useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { FileModel } from '@nimles/models/lib/media';
import {
  Button,
  Column,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';

const getBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });
};

const ImageContainer = styled.div`
  position: relative;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
`;

const DropZone = styled.div`
  border: 2px dashed ${({ theme }) => theme.input.borderColor};
  border-radius: ${({ theme }) => theme.input.borderRadius}px;
  padding: ${({ theme }) =>
    `${theme.input.paddingVertical}px ${theme.input.paddingHorizontal}px`};
  cursor: pointer;
`;

interface Props extends FieldProps {
  label: string;
  placeholder?: string;
  onFilesLoaded?: (files: File[]) => Promise<FileModel[]>;
  disabled?: boolean;
  multi?: boolean;
}

const FileInput: FC<Props> = ({
  field,
  form,
  label,
  placeholder,
  onFilesLoaded,
  disabled,
  multi,
}) => {
  const onDrop = async (files: File[]) => {
    files = multi ? files : [files[0]];
    const values = await (onFilesLoaded
      ? onFilesLoaded(files)
      : Promise.all(
          files.map(
            async (file): Promise<FileModel> => {
              const uri = await getBase64(file);
              return {
                uri,
                name: file.name,
                mimeType: file.type,
              };
            }
          )
        ));

    console.log('File', field.value);

    form.setFieldValue(
      field.name,
      multi
        ? Array.isArray(field.value)
          ? [...field.value, ...values]
          : values
        : values[0]
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    form.setFieldValue(field.name, undefined);
  };

  const handleIndexRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    form.setFieldValue(
      field.name,
      field.value.filter((_, fileIndex: number) => fileIndex !== index)
    );
  };

  return disabled ? (
    <Image src={field.value?.uri} alt="" />
  ) : (
    <>
      <label>{label}</label>
      <DropZone key={field.name} {...getRootProps()}>
        <input {...getInputProps()} />
        {multi ? (
          <List>
            {!field.value?.length ? (
              <p>{placeholder}</p>
            ) : (
              field.value.map(({ uri, mimeType, name }: FileModel, index) => (
                <ListItem key={index}>
                  <Row wrap="wrap" align="center">
                    <Column padding="0" xs={20} align="center">
                      {mimeType?.toLowerCase()?.indexOf('image') === 0 ? (
                        <Image src={uri} alt="" />
                      ) : (
                        mimeType?.split('/').map((s) => <div>{s}</div>)
                      )}
                    </Column>
                    <Column flex>
                      <div>{name}</div>
                    </Column>
                    <Column>
                      <Button onClick={(e) => handleIndexRemove(e, index)}>
                        Remove
                      </Button>
                    </Column>
                  </Row>
                </ListItem>
              ))
            )}
          </List>
        ) : !field.value ? (
          <p>{placeholder}</p>
        ) : (
          <ImageContainer>
            <Image src={field.value?.uri} alt="" />
            <RemoveButton onClick={handleRemove}>Remove</RemoveButton>
          </ImageContainer>
        )}
      </DropZone>
    </>
  );
};

interface FileProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onFilesLoaded?: (files: File[]) => Promise<FileModel[]>;
  multi?: boolean;
}

export const FileField: FC<FileProps> = (props) => (
  <Field {...props} component={FileInput} />
);

export default FileField;
