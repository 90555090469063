import {
  CategoryModel,
  FileModel,
  ProductGroupModel,
  ProductPropertyModel,
  QuoteModel,
  QuoteRequestModel,
} from '@nimles/models';
import {
  loadQuoteRequest,
  loadFiles,
  loadQuote,
  loadPublicFile,
  loadFile,
} from '@nimles/react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Column,
  Image,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { QuoteForm } from '../../forms/quote/QuoteForm';
import { Property, PropertyTitle } from '../../Property';

export const Quote = ({ quoteId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );
  const quoteRequests = useSelector<RootState, QuoteRequestModel[]>(
    ({ quoteRequests }) => quoteRequests.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const files = useSelector<RootState, FileModel[]>(
    ({ files }) => files.values
  );
  const publicFiles = useSelector<RootState, FileModel[]>(
    ({ publicFiles }) => publicFiles.values
  );
  const publicProductProperties = useSelector<
    RootState,
    ProductPropertyModel[]
  >(({ publicProductProperties }) => publicProductProperties.values);
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  useEffect(() => {
    if (quoteId) {
      dispatch(loadQuote(quoteId));
    }
  }, [accessToken, quoteId]);

  const quote = useMemo(() => quotes.find(({ id }) => id === quoteId), [
    quotes,
    quoteId,
  ]);

  useEffect(() => {
    if (quote) {
      Promise.all(
        quote.lines.reduce((promises, line) => {
          if (!line.fileIds) {
            return promises;
          }

          return [
            ...promises,
            ...line.fileIds
              .filter((fileId) => !files.some(({ id }) => id === fileId))
              .map((fileId) => dispatch(loadFile(fileId))),
          ];
        }, [])
      );
    }
  }, [quote]);

  const quoteRequestId = quote?.head?.quoteRequestId;

  useEffect(() => {
    if (quoteRequestId) {
      dispatch(loadQuoteRequest(quoteRequestId));
    }
  }, [accessToken, quoteRequestId]);

  const quoteRequest = quoteRequests.find(({ id }) => id === quoteRequestId);

  useEffect(() => {
    if (quoteRequest) {
      quoteRequest.lines.forEach((line) => {
        if (!line.fileIds) {
          return;
        }
        line.fileIds
          .filter((fileId) => !publicFiles.some(({ id }) => id === fileId))
          .forEach((fileId) => dispatch(loadPublicFile(fileId)));
      });
    }
  }, [quoteRequest]);

  if (!quoteRequest) {
    return null;
  }

  const {
    head: { categoryId, quoteRequestNumber, quoteRequestDate },
    lines,
  } = quoteRequest;

  const category = publicCategories.find(({ id }) => id === categoryId);

  return (
    <Row wrap="wrap">
      <Column xs={100} lg={40}>
        <Card>
          <CardHeader>
            <CardTitle>{t('title.quoteRequest')}</CardTitle>
            <CardSubtitle>{category?.name}</CardSubtitle>
            <List>
              <ListItem border>Number: {quoteRequestNumber}</ListItem>
              <ListItem border>
                Date: {moment(quoteRequestDate).format('MMM D')}
              </ListItem>
            </List>
          </CardHeader>
          <CardBody>
            {lines.map(
              ({
                id,
                categoryId,
                productGroupId,
                productProperties,
                description,
                fileIds,
              }) => {
                const category = publicCategories.find(
                  ({ id }) => id === categoryId
                );
                const productGroup = publicProductGroups.find(
                  ({ id }) => id === productGroupId
                );

                return (
                  <div key={id}>
                    <h4>{productGroup?.name}</h4>
                    {productProperties.map(({ productPropertyId, values }) => {
                      const productProperty = publicProductProperties.find(
                        ({ id }) => id === productPropertyId
                      );

                      if (!productProperty) {
                        return (
                          <div key={productPropertyId}>{productPropertyId}</div>
                        );
                      }

                      const {
                        id,
                        name,
                        title,
                        propertyType,
                        options,
                      } = productProperty;

                      return (
                        <Property key={id}>
                          <PropertyTitle>{title}</PropertyTitle>
                          {values?.map((value, index) => (
                            <div key={index}>
                              {propertyType.indexOf('Option') !== -1
                                ? options.find(
                                    ({ id }) => value === id.replace(/-/g, '')
                                  )?.name ?? value
                                : value}
                            </div>
                          ))}
                        </Property>
                      );
                    })}
                    <PropertyTitle>Description</PropertyTitle>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                    <PropertyTitle>Files</PropertyTitle>
                    <List>
                      {fileIds
                        ?.map(
                          (fileId) =>
                            publicFiles.find(({ id }) => id === fileId) ?? {
                              id: fileId,
                            }
                        )
                        .map(({ id, name, mimeType, uri }) => (
                          <ListItem>
                            <Row wrap="wrap" align="center">
                              <Column xs={100} sm={20} align="center">
                                {!mimeType ? (
                                  id
                                ) : mimeType.toLowerCase()?.indexOf('image') ===
                                  0 ? (
                                  <Image src={uri} alt="" />
                                ) : (
                                  mimeType
                                    ?.split('/')
                                    .map((s) => <div>{s}</div>)
                                )}
                              </Column>
                              <Column flex>
                                <div>{name}</div>
                              </Column>
                            </Row>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                );
              }
            )}
          </CardBody>
        </Card>
      </Column>
      <Column xs={100} lg={60}>
        <QuoteForm quote={quote} />
      </Column>
    </Row>
  );
};
