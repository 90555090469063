/** @jsx jsx */
import { useEffect } from 'react';
import { Router } from '@reach/router';
import Layout from '../layouts/Layout';
import { CompanyRoute } from '../components/company/CompanyRoute';
import { Profile } from '../components/company/Profile';
import { Default } from '../components/company/Default';
import { Column, Container, Row } from '@nimles/react-web-components';
import { Quotes } from '../components/company/quotes/Quotes';
import {
  loadOrders,
  loadPublicCategories,
  loadPublicProductGroups,
  loadPublicProductProperties,
  loadQuoteRequests,
  loadQuotes,
} from '@nimles/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { jsx } from '@emotion/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Navbar, NavbarLink } from '../components/Navbar';
import { QuoteRequests } from '../components/company/requests/QuoteRequests';
import { StaticQuery, graphql } from 'gatsby';

const CompanyPage = ({ location, site }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  useEffect(() => {
    dispatch(loadPublicCategories());
    dispatch(loadOrders());
    dispatch(loadQuotes());
    dispatch(loadQuoteRequests({ onlyPublic: true }));
    dispatch(loadPublicProductGroups());
    dispatch(loadPublicProductProperties());
  }, [accessToken]);

  const { pathname } = location;

  return (
    <Layout
      header
      footer
      headerAlwaysOpen
      location={location}
      siteMetadata={site.siteMetadata}
    >
      <Navbar>
        <Container>
          <Row wrap="wrap">
            <Column>
              <NavbarLink
                to="/company/profile"
                isActive={pathname.indexOf('/company/profile') === 0}
                activeClassName="active"
              >
                {t('title.companyProfile')}
              </NavbarLink>
            </Column>
            <Column>
              <NavbarLink
                to="/company/requests"
                isActive={pathname.indexOf('/company/requests') === 0}
                activeClassName="active"
              >
                {t('title.quoteRequests')}
              </NavbarLink>
            </Column>
            <Column>
              <NavbarLink
                to="/company/quotes"
                isActive={pathname.indexOf('/company/quotes') === 0}
                activeClassName="active"
              >
                {t('title.quotes')}
              </NavbarLink>
            </Column>
          </Row>
        </Container>
      </Navbar>
      <Container>
        <Router basepath="/company">
          <CompanyRoute path="/profile" component={Profile} />
          <CompanyRoute path="/requests/*" component={QuoteRequests} />
          <CompanyRoute path="/quotes/*" component={Quotes} />
          <CompanyRoute path="/" component={Default} />
        </Router>
      </Container>
    </Layout>
  );
};

export const Company = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            tenantId
            googleApiKey
          }
        }
      }
    `}
    render={(props) => <CompanyPage {...props} location={location} />}
  />
);

export default Company;
