import React, { FC, useState } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Row,
  Column,
  Button,
  Section,
  Container,
  Input,
  IconButton,
} from '@nimles/react-web-components';
import { InputField, TextAreaField } from '@nimles/react-web-forms';
import { QuillField } from '../../fields/QuillField';
import FileField from '../../fields/FileField';
import { getImageUrl, socialArray } from '../../../utils';
import { AddressModel, LocationModel, OrganizationModel } from '@nimles/models';
import { Map, toLatLng, toLocation } from '../../map/Map';
import { Marker } from '@react-google-maps/api';
import { FormGroup } from '../FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

function codeAddress({
  street,
  postalCode,
  city,
}: AddressModel): Promise<LocationModel> {
  return new Promise((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      { address: `${street}, ${postalCode} ${city}` },
      function (results, status) {
        if (status == 'OK') {
          resolve(toLocation(results[0]?.geometry?.location?.toJSON()));
        } else {
          reject(status);
        }
      }
    );
  });
}

interface AddressFieldSetProps {
  label: string;
  name: string;
  address: AddressModel;
  onGeocoded: (location: LocationModel) => void;
}

const AddressFieldSet = ({
  label,
  name,
  address,
  onGeocoded,
}: AddressFieldSetProps) => {
  const handleGeocode = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const location = await codeAddress(address);
    onGeocoded(location);
  };

  return (
    <fieldset>
      <legend>{label}</legend>
      <FormGroup>
        <InputField
          name={name + '.street'}
          label="Street"
          placeholder="Street"
        />
        <InputField
          name={name + '.postalCode'}
          label="Postal code"
          placeholder="Postal code"
        />
        <InputField name={name + '.city'} label="City" placeholder="City" />
        <InputField name={name + '.state'} label="State" placeholder="State" />
        <InputField
          name={name + '.country'}
          label="Country"
          placeholder="Country"
        />
      </FormGroup>

      <Row>
        <Column>
          <Button raised secondary onClick={handleGeocode}>
            Geocode
          </Button>
        </Column>
      </Row>
    </fieldset>
  );
};

const SocialButton = styled(Button)`
  svg {
    margin: 0;
  }
`;

interface Props {
  organization?: OrganizationModel;
  onSubmit: (x: any) => void;
}

export const OrganizationForm: FC<Props> = ({ organization, onSubmit }) => {
  if (!organization) {
    return null;
  }

  const values = {
    name: organization.name || '',
    email: organization.email || '',
    corporateIdentity: organization.corporateIdentity || '',
    description: (organization && organization.description) || '',
    phoneNumber: organization.phoneNumber || '',
    website: organization.website || '',
    address: {
      street: organization.address?.street || '',
      city: organization.address?.city || '',
      postalCode: organization.address?.postalCode || '',
    },
    logo: organization.logoId
      ? { id: organization.logoId, uri: getImageUrl(organization.logoId) }
      : null,
    banner: organization.bannerId
      ? { id: organization.bannerId, uri: getImageUrl(organization.bannerId) }
      : null,
    socialLinks: organization.socialLinks || {},
    location: {
      latitude: null,
      longitude: null,
      ...(organization.location || {}),
    },
  };

  const { t } = useTranslation();

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      onSubmit(values);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      render={({ values, handleSubmit, isSubmitting, setFieldValue }) => {
        const mapLocation =
          values.location.latitude &&
          values.location.longitude &&
          values.location;
        return (
          <form onSubmit={handleSubmit} noValidate name="simpleForm">
            <Row>
              <Column flex>
                <fieldset>
                  <legend>{t('title.information')}</legend>
                  <InputField
                    name="name"
                    label={t('prop.name')}
                    placeholder={t('prop.name')}
                  />
                  <InputField
                    name="corporateIdentity"
                    label={t('prop.corporateIdentity')}
                    placeholder={t('prop.corporateIdentity')}
                  />
                  <InputField
                    name="email"
                    label={t('prop.email')}
                    placeholder={t('prop.email')}
                  />
                  <InputField
                    name="phoneNumber"
                    label={t('prop.phoneNumber')}
                    placeholder={t('prop.phoneNumber')}
                  />
                  <InputField
                    name="website"
                    label={t('prop.website')}
                    placeholder={t('prop.website')}
                  />
                </fieldset>
              </Column>
              <Column flex>
                <fieldset>
                  <legend>{t('prop.images')}</legend>
                  <FileField
                    name="logo"
                    label={t('prop.logo')}
                    placeholder={t('prop.logo')}
                  />
                  <FileField
                    name="banner"
                    label={t('prop.banner')}
                    placeholder={t('prop.banner')}
                  />
                </fieldset>
              </Column>
            </Row>
            <Row>
              <Column flex></Column>
              <Column flex></Column>
            </Row>
            <fieldset>
              <QuillField
                name="description"
                label={t('prop.description')}
                placeholder={t('prop.description')}
              />
            </fieldset>

            <fieldset>
              <legend>Social Links</legend>
              {values.socialLinks
                ? Object.entries(values.socialLinks).map(
                    ([name, url], index) => (
                      <FormGroup>
                        <Row align="flex-end">
                          <Column flex>
                            <InputField
                              label={
                                socialArray.find((s) => s.name === name)
                                  ?.displayName ?? name
                              }
                              name={`socialLinks.${name}`}
                              placeholder=""
                              required
                            />
                          </Column>
                          <Column>
                            <IconButton
                              color="danger"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue('socialLinks', {
                                  ...values.socialLinks,
                                  [name]: undefined,
                                });
                              }}
                            >
                              Remove
                            </IconButton>
                          </Column>
                        </Row>
                      </FormGroup>
                    )
                  )
                : null}
              {socialArray
                .filter(({ name }) => !values.socialLinks?.[name])
                .map(({ icon, name }) => (
                  <SocialButton
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue('socialLinks', {
                        ...values.socialLinks,
                        [name]: '',
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={icon} size="lg" />
                  </SocialButton>
                ))}
            </fieldset>
            <Row wrap="wrap">
              <Column xs={100} md={50}>
                <AddressFieldSet
                  name="address"
                  label="Address"
                  address={values.address}
                  onGeocoded={(location) => setFieldValue('location', location)}
                />
              </Column>
              <Column xs={100} md={50}>
                <fieldset>
                  <legend>Location</legend>
                  <FormGroup>
                    <InputField
                      label="Latitude"
                      type="number"
                      name="location.latitude"
                    />
                    <InputField
                      label="Longitude"
                      type="number"
                      name="location.longitude"
                    />
                  </FormGroup>
                  <Map
                    center={
                      mapLocation || { latitude: 62.3858, longitude: 16.322 }
                    }
                    locations={mapLocation ? [mapLocation] : []}
                  >
                    {mapLocation ? (
                      <Marker position={toLatLng(mapLocation)} />
                    ) : null}
                  </Map>
                </fieldset>
              </Column>
            </Row>
            <Row justify="flex-end">
              <Column>
                <Button primary raised type="submit">
                  {t(isSubmitting ? 'action.saving' : 'action.save')}
                </Button>
              </Column>
            </Row>
          </form>
        );
      }}
    />
  );
};
